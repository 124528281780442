import qs from 'qs';
import type {
  RequireAtLeastOne,
  SnakeCasedProperties,
  SnakeCasedPropertiesDeep,
} from 'type-fest';
import type {
  Document,
  DocumentSplitSuggestionResponse,
  FillConfig,
  FormMatch,
} from 'src/types/proto/documents';
import type { FormConfig } from 'src/types/proto/reform';
import type {
  SearchFormPublicResponse,
  SearchFormRequest,
  SearchFormResponse,
} from 'src/types/proto/services/document_form_public_service';
import type {
  CreateDocumentRequest,
  getFormsByGfpFlowIdResponse,
  GetFormSuggestionsRequest,
} from 'src/types/proto/services/document_public_service';
import type {
  GetDocumentByUrlRequest,
  GetEditableDocumentByTdvRequest,
  GetEditableDocumentByTdvResponse,
  PspdfkitDocument,
} from 'src/types/proto/services/pspdfkit_public_service';
import type { ZipTransactionDocumentVersionsRequest } from 'src/types/proto/services/transaction_public_service';
import type { ApiResponseData } from 'src/types/utils';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import multiDownload from 'multi-download';
import BaseApi from '../base-api';
import { ZipFileOptions } from './shared-packet';

export default class Documents extends BaseApi {
  createDocument(
    file: Omit<CreateDocumentRequest, 'analyze' | 'decrypt'>,
    analyze = false,
    decrypt = false
  ) {
    return this.post<Document>('/documents', {
      url: file.url,
      filename: file.filename,
      byteSize: file.byteSize,
      thumbnailUrl: file.thumbnailUrl,
      analyze,
      decrypt,
    });
  }

  searchForms = ({
    query,
    libraryUuids,
    state,
    county,
  }: RequireAtLeastOne<SearchFormRequest, 'libraryUuids' | 'state'>) => {
    return this.get<
      ApiResponseData<SearchFormResponse | SearchFormPublicResponse>
    >('/documents/forms/search', {
      params: {
        query,
        ...(libraryUuids
          ? {
              library_uuids: libraryUuids,
            }
          : null),
        ...(state
          ? {
              state,
              county,
            }
          : null),
      },
    });
  };

  downloadByTdId(tdId: string): Promise<void> {
    // multi-download library doesn't support relative urls
    const requestCfg = {
      url: `/documents/download`,
      params: { td_id: tdId },
    };

    return multiDownload([this.getFullUri(requestCfg)]);
  }

  getBlobByTdId(tdId: string): Promise<Blob> {
    return this.get('/documents/download', {
      responseType: 'blob',
      params: formatToSnakeCase({
        tdId,
      }),
    }) as any;
  }

  async downloadMultipleDocsAsZip(
    tdIds: string[],
    zipFilename: string,
    options: ZipFileOptions = {}
  ) {
    const { numberDocuments = false } = options;
    const zipFileUrl = this.getFullUri({
      url: '/documents/zip_on_the_fly',
      params: formatToSnakeCase({
        tdIds,
        output: zipFilename,
        numberDocuments,
      }),
    });
    await multiDownload([zipFileUrl]);
  }

  getZipFileBlob({
    transactionId,
    tdvIds,
    filename,
  }: ZipTransactionDocumentVersionsRequest): Promise<Blob> {
    return this.get('/transactions/zip_transaction_document_versions', {
      responseType: 'blob',
      params: {
        transactionId,
        tdvIds,
        filename,
      },
      paramsSerializer: (params) => qs.stringify(params, { indices: false }),
    }) as any;
  }

  getFormSuggestions<T extends GetFormSuggestionsRequest>({
    tdIds,
    tdvIds,
    transactionId,
    mode = 'tabbing',
  }: T) {
    type Suggestion = T['mode'] extends 'splitting'
      ? DocumentSplitSuggestionResponse
      : FormMatch;

    return this.get<Record<string, Suggestion>>(
      `/documents/suggestions/${transactionId}`,
      {
        params: {
          td_ids: tdIds && tdIds.length ? tdIds : undefined,
          tdv_ids: tdvIds && tdvIds.length ? tdvIds : undefined,
          mode,
        },
      }
    );
  }

  getGfpForms(gfpFlowId: string) {
    return this.get<ApiResponseData<getFormsByGfpFlowIdResponse>>(
      `/documents/gfp_forms/${encodeURIComponent(gfpFlowId)}`
    );
  }

  getPspdfkitDocumentByForm(formId: string) {
    return this.get<SnakeCasedProperties<PspdfkitDocument>>(
      `/pspdfkit/document/by_form/${encodeURIComponent(formId)}`
    );
  }

  getPspdfkitDocumentByDoc(
    documentUuid: string,
    options: { isCoverSheetDoc?: boolean } = {}
  ) {
    const { isCoverSheetDoc = false } = options || {};
    return this.get<SnakeCasedProperties<PspdfkitDocument>>(
      `/pspdfkit/document/by_doc/${encodeURIComponent(documentUuid)}${
        isCoverSheetDoc ? '?cover-sheet-doc=true' : ''
      }`
    );
  }

  getPspdfkitDocumentByUrl(
    url: string,
    options: { isCoverSheetDoc?: boolean } = {}
  ) {
    const { isCoverSheetDoc = false } = options || {};
    return this.post<SnakeCasedProperties<PspdfkitDocument>>(
      `/pspdfkit/document/by_url${
        isCoverSheetDoc ? '?cover-sheet-doc=true' : ''
      }`,
      {
        url,
      } as GetDocumentByUrlRequest
    );
  }

  getFillConfigByFormUuid(formUuid: string, tdId?: string) {
    return this.get<FillConfig>(
      `/forms/${encodeURIComponent(formUuid)}/fill_config`,
      tdId
        ? {
            params: {
              td_id: tdId,
            },
          }
        : undefined
    );
  }

  getPspdfkitEditableDocumentByTdv(transactionId: string, tdvId: string) {
    return this.post<
      SnakeCasedPropertiesDeep<GetEditableDocumentByTdvResponse>
    >(
      '/pspdfkit/document/editable_by_tdv',
      formatToSnakeCase({
        transactionId,
        tdvId,
      } as GetEditableDocumentByTdvRequest)
    );
  }

  getFormConfig(formUuid: string) {
    return this.get<FormConfig>(
      `/forms/${encodeURIComponent(formUuid)}/form_config`
    );
  }
}
